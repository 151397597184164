import { gql } from '@apollo/client';
export default gql`
  query MerchantProfile($user_id: uuid! = "") {
    client_profile(where: { user_id: { _eq: $user_id } }) {
      id
      company_name
      phone_number
      address
      address2
      country_code
      wallet_id
      display_currency
      country {
        code
        name
        phone_code
      }
      is_approved
      is_active
      region # state
      region2 # city
      default_currency {
        code
        name
        fraction_size
        symbol
        template
      }
      postal_code
      updated_at
      description
      logo_file {
        url
        id
      }
      client_survey {
        additional_info
        avg_budget_per_campaign_usd_cents
        avg_kols_per_campaign
        display_currency
        id
        is_currently_running_campaigns
        number_of_campaigns_annually
        updated_at
      }
      user {
        id
        picture {
          url
        }
      }
    }
  }
`;
