import { gql } from '@apollo/client';
export default gql`
  query MyUser {
    user {
      id
      phone_number
      email
      family_name
      given_name
      activated
      picture {
        id
        url
      }
    }
  }
`;
