/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useMemo } from 'react';

import GET_USER from '../queries/onboarding/getMerchantUser';
import GET_PROFILE from '../queries/onboarding/getMerchantProfile';
import GET_WALLET from '../queries/wallet/myWallet';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { onUserVar } from '../cache/action';
import GET_CURRENT_USER from '../queries/local/getCurrentUser';
import ClientProfileContext from '../containers/ClientProfileProvider';

export default function useGuard() {
  const { data: infoUser } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'cache-only',
  });

  const { currentProfile } = useContext(ClientProfileContext);

  // eslint-disable-next-line no-unused-vars
  const { isLoading: isAuth0Loading, isAuthenticated } = useAuth0();

  // eslint-disable-next-line no-unused-vars
  const [getUser, { data: userData, loading: userLoading, called: userCalled }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'no-cache',
  });
  // eslint-disable-next-line no-unused-vars
  const [getProfile, { data: profileData, loading: profileLoading, called: profileCalled }] = useLazyQuery(GET_PROFILE, {
    skip: !userData,
    variables: {
      user_id: userData?.user?.[0]?.id || infoUser?.currentUser?.id,
    },
  } as any);

  const [getWallet, { data: wallet, loading: walletLoading, called: walletCalled }] = useLazyQuery(GET_WALLET, {
    variables: {
      kol_or_merchant_profile_id: currentProfile?.id,
      profile_type: 'MERCHANT',
    },
  });

  const isLogged = useMemo(() => {
    return infoUser?.currentUser?.id || false;
  }, [infoUser]);

  const isUserReady = useMemo(() => {
    if (isAuthenticated) {
      return infoUser?.currentUser?.isReady && !!profileData && !!userData;
    }
    return infoUser?.currentUser?.isReady;
  }, [infoUser, profileData, userData]);

  const isLoading = useMemo(() => {
    return userLoading || isAuth0Loading || !isUserReady || profileLoading;
  }, [userLoading, isAuth0Loading, isUserReady, profileLoading]);

  const isAuth0Login = useMemo(() => {
    return isAuthenticated;
  }, [isAuthenticated]);

  const isAccountActive = useMemo(() => {
    return !!infoUser?.currentUser?.activated;
  }, [infoUser]);

  const isProfileApproved = useMemo(() => {
    return !!infoUser?.currentUser?.profile?.is_approved;
  }, [infoUser]);

  const isProfileActive = useMemo(() => {
    return !!infoUser?.currentUser?.profile?.is_active;
  }, [infoUser]);

  const isCreateSurvey = useMemo(() => {
    return !!infoUser?.currentUser?.profile?.client_survey;
  }, [infoUser]);

  const isCreateCompanyDetail = useMemo(() => {
    return !!infoUser?.currentUser?.profile?.id;
  }, [infoUser]);

  const isProfileCreated = useMemo(() => {
    return !!isCreateCompanyDetail && !!isCreateSurvey;
  }, [isCreateSurvey, isCreateCompanyDetail]);

  const handleUpdateUser = useCallback(
    (newUser: any) => {
      onUserVar({
        ...infoUser?.currentUser,
        ...newUser,
      });
    },
    [infoUser?.currentUser],
  );

  const handleUpdateProfile = useCallback(
    (newProfile: any) => {
      onUserVar({
        ...infoUser?.currentUser,
        profile: {
          ...infoUser?.currentUser?.profile,
          ...newProfile,
        },
      });
    },
    [infoUser?.currentUser],
  );

  useEffect(() => {
    if (isAuth0Loading || isLogged) return;
    if (!isAuthenticated) {
      onUserVar({ isReady: true });
      return;
    }

    getUser();
  }, [isAuthenticated, isAuth0Loading, isLogged]);

  // useEffect(() => {
  //   if (!isAuthenticated || isAccountActive) return;
  //   getUser();
  // }, [isAuthenticated, isAccountActive]);

  useEffect(() => {
    if (!userData || isProfileCreated) return;
    getProfile();
  }, [userData, isProfileCreated]);

  useEffect(() => {
    if (walletLoading || !walletCalled) return;

    if (wallet) {
      handleUpdateProfile({
        wallet: wallet.myWallet.amount_usd_cents,
      });
    }
  }, [walletLoading, walletCalled, wallet, handleUpdateProfile]);

  useEffect(() => {
    if (userLoading || !userCalled) return;
    if (profileLoading || !profileCalled) return;
    if (!userData) {
      onUserVar({ isReady: true });
      return;
    }

    const user = (userData && userData.user && userData?.user?.[0]) || {};
    const profile = currentProfile?.clientProfile;

    onUserVar({
      ...user,
      profile,
      isReady: true,
    });
  }, [userData, profileData, userLoading, userCalled, profileCalled, profileLoading, onUserVar, currentProfile]);

  return {
    isLogged,
    isAuth0Login,
    isLoading,
    isAccountActive,
    isProfileCreated,
    isProfileApproved,
    isCreateSurvey,
    isCreateCompanyDetail,
    user: infoUser?.currentUser || null,
    onRefreshWallet: getWallet,
    onRefreshUser: getUser,
    onRefreshProfile: getProfile,
    onUpdateUser: handleUpdateUser,
    onUpdateProfile: handleUpdateProfile,
    isProfileActive,
  };
}
