import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from './utils/constants';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ToastProvider } from 'react-toast-notifications';
import MerchantRoute from './components/commons/MerchantRoute';
import CustomAuth0Provider from './containers/CustomAuth0Provider';
import CustomApolloProvider from './containers/CustomApolloProvider';
import { ThemeProvider } from '@mui/material/styles';
import theme from './style/style';
import { ClientProfileProvider } from './containers/ClientProfileProvider';
import CenteredFallback from './components/commons/CenteredFallback';

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const ClientRegister = React.lazy(() => import('./pages/ClientRegister'));
const UnAvailableAccount = React.lazy(() => import('./pages/PendingRegister'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Auth0Callback = React.lazy(() => import('./containers/authentication/Auth0Callback'));
const TokenExpired = React.lazy(() => import('./pages/PendingRegister'));
const Authorization = React.lazy(() => import('./containers/authentication/Authorization'));
const Teams = React.lazy(() => import('./pages/teams/Teams'));
const MerchantRegister = React.lazy(() => import('./pages/MerchantRegister'));
const PendingRegister = React.lazy(() => import('./pages/PendingRegister'));
const SelectMerchant = React.lazy(() => import('./pages/SelectMerchant'));
const Campaigns = React.lazy(() => import('./pages/campaigns/Campaigns'));
const CampaignCreate = React.lazy(() => import('./pages/campaigns/CampaignCreate'));
const CampaignDetail = React.lazy(() => import('./pages/campaigns/CampaignDetail'));
const CampaignListOfKols = React.lazy(() => import('./pages/campaigns/CampaignListOfKols'));
const CampaignReport = React.lazy(() => import('./pages/campaigns/CampaignReport'));
const CampaignChat = React.lazy(() => import('./pages/campaigns/CampaignChat'));
const CampaignDeliverables = React.lazy(() => import('./pages/campaigns/CampaignDeliverable'));
const CompanyProfile = React.lazy(() => import('./pages/CompanyProfile'));
const UserProfile = React.lazy(() => import('./pages/UserProfile'));
const MyWallet = React.lazy(() => import('./pages/MyWallet'));
const ContentMetrics = React.lazy(() => import('./pages/campaigns/ContentMetrics'));
const PublicCampaignTerm = React.lazy(() => import('./components/campaigns/CreateCampaign/CampaignTerm/PublicCampaignTerm'));

function App() {
  return (
    <React.Fragment>
      <GlobalStyle />
      <ToastProvider newestOnTop={true}>
        <BrowserRouter>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CustomAuth0Provider>
              <CustomApolloProvider>
                <ClientProfileProvider>
                  <ThemeProvider theme={theme}>
                    <Switch>
                      <Suspense fallback={<CenteredFallback />}>
                        <MerchantRoute private path={PRIVATE_ROUTE.merchantRegister} component={MerchantRegister} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.dashboard} component={Dashboard} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.pendingReview} component={PendingRegister} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.accountUnavailable} component={UnAvailableAccount} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.selectMerchant} component={SelectMerchant} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.clientRegister} component={ClientRegister} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.campaigns} component={Campaigns} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.campaignsOfProfile} component={Campaigns} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.campaignsCreate} component={CampaignCreate} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.campaignsDetails} component={CampaignDetail} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.campaignsListOfKols} component={CampaignListOfKols} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.campaignsReport} component={CampaignReport} exact />
                        <MerchantRoute private path={PRIVATE_ROUTE.campaignsChat} component={CampaignChat} />
                        <MerchantRoute private path={PRIVATE_ROUTE.campaignsDeliverables} component={CampaignDeliverables} />
                        <MerchantRoute private path={PRIVATE_ROUTE.companyProfile} component={CompanyProfile} />
                        <MerchantRoute private path={PRIVATE_ROUTE.userProfile} component={UserProfile} />
                        <MerchantRoute private path={PRIVATE_ROUTE.wallet} component={MyWallet} />
                        <MerchantRoute private path={PRIVATE_ROUTE.contentMetrics} component={ContentMetrics} />
                        <Route path={PRIVATE_ROUTE.publishCampaignTerms} component={PublicCampaignTerm} />
                        <MerchantRoute private path={PRIVATE_ROUTE.teams} component={Teams} exact />

                        <MerchantRoute path="/" component={Authorization} exact />
                        <MerchantRoute path={PUBLIC_ROUTE.login} component={Authorization} exact />
                        <MerchantRoute path={PUBLIC_ROUTE.notFound} component={NotFound} exact />
                        <MerchantRoute path={PUBLIC_ROUTE.auth0Callback} component={Auth0Callback} />
                        <MerchantRoute path={PUBLIC_ROUTE.tokenExpired} component={TokenExpired} exact />
                      </Suspense>
                      <Redirect from="/" to={PRIVATE_ROUTE.campaigns} exact />
                      <Redirect from="*" to={PUBLIC_ROUTE.notFound} exact />
                    </Switch>
                  </ThemeProvider>
                </ClientProfileProvider>
              </CustomApolloProvider>
            </CustomAuth0Provider>
          </MuiPickersUtilsProvider>
        </BrowserRouter>
      </ToastProvider>
    </React.Fragment>
  );
}

export default App;

const GlobalStyle = createGlobalStyle`

`;
