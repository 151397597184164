import { gql } from '@apollo/client';

export default gql`
  query ListMerchant($email: String) {
    client_profile_user_role(where: { email: { _eq: $email } }) {
      email
      id
      role
      isAccepted
      client_profile_id
      client_profile {
        id
        company_name
        phone_number
        address
        address2
        country_code
        wallet_id
        display_currency
        country {
          code
          name
          phone_code
        }
        is_approved
        is_active
        region # state
        region2 # city
        default_currency {
          code
          name
          fraction_size
          symbol
          template
        }
        postal_code
        updated_at
        description
        logo_file {
          url
          id
        }
        client_survey {
          additional_info
          avg_budget_per_campaign_usd_cents
          avg_kols_per_campaign
          display_currency
          id
          is_currently_running_campaigns
          number_of_campaigns_annually
          updated_at
        }
      }
    }
  }
`;
