import { InMemoryCache } from '@apollo/client';
import { onUserVar } from './action';
import { onListCountryVar } from './action';
import { onListCurrencyVar } from './action';
import { onListCategoryValue } from './action';

const memoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        currentUser: {
          read() {
            return onUserVar();
          },
        },
        listCountry: {
          read() {
            return onListCountryVar();
          },
        },
        listCurrency: {
          read() {
            return onListCurrencyVar();
          },
        },
        listCategoryValue: {
          read() {
            return onListCategoryValue();
          },
        },
      },
    },
  },
});

export default memoryCache;
