import { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

const useToastError = () => {
  const { addToast } = useToasts();

  const toastError = useCallback(
    (error) => {
      addToast(error, { appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000 });
    },
    [addToast],
  );

  return {
    toastError,
  };
};

export default useToastError;
