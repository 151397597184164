import { useMemo } from 'react';
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from '../utils/constants';

export default function useExcludeRoute(route) {
  const pathExclude = [
    PRIVATE_ROUTE.merchantRegister,
    PRIVATE_ROUTE.pendingReview,
    PUBLIC_ROUTE.login,
    PRIVATE_ROUTE.selectMerchant,
    PUBLIC_ROUTE.tokenExpired,
  ];

  return useMemo(() => {
    return pathExclude.includes(route);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);
}
