import { Auth0Provider, LocalStorageCache } from '@auth0/auth0-react';
import { REACT_APP_AUTH0_HOST, REACT_APP_AUTH0_MERCHANT_CLIENT_ID } from '../configs';

const cache = new LocalStorageCache();
const CustomAuth0Provider = ({ children }) => {
  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_HOST as any}
      clientId={REACT_APP_AUTH0_MERCHANT_CLIENT_ID as any}
      redirectUri={window.location.origin + '/auth0/callback'}
      authorizeTimeoutInSeconds={10}
      cache={cache}
    >
      {children}
    </Auth0Provider>
  );
};

export default CustomAuth0Provider;
