import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ApolloProvider, ApolloClient, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';

import memoryCache from '../cache/cache';

import useToastError from '../hooks/useToastError';
import { ROUTES } from '../utils/constants';
import { REACT_APP_APOLLO_GRAPHQL_URL, REACT_APP_APOLLO_GRAPHQL_WEBSOCKET_URL } from '../configs';

const ApolloProviderWithAuth0 = ({ children }: any): any => {
  const { getIdTokenClaims, isAuthenticated, isLoading, logout } = useAuth0();
  const client = React.useRef<ApolloClient<any>>();
  const clientWithSocket = React.useRef<ApolloClient<any>>();
  const tokenRef = React.useRef<string>();
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const { toastError } = useToastError();

  useEffect(() => {
    if (!isAuthenticated || isLoading) return;
    setLoading(true);
    const getToken = async () => {
      try {
        const claimUser = await getIdTokenClaims();
        if (claimUser && claimUser.__raw) {
          setToken(claimUser.__raw);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toastError('Something wrongs, please try again');
      }
    };
    getToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);
  if (loading) return 'Loading....';
  return initApollo();

  function initApollo() {
    const { httpLink, authLink, defaultOptions } = getConfig();

    if (!isAuthenticated || isLoading || !token) {
      if (!client.current) {
        client.current = new ApolloClient({
          link: authLink.concat(httpLink),
          cache: memoryCache,
          defaultOptions: defaultOptions as any,
        });
      }
      return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
    }
    if (tokenRef.current !== token || !clientWithSocket.current) {
      tokenRef.current = token;
      const wsLink = new WebSocketLink({
        uri: REACT_APP_APOLLO_GRAPHQL_WEBSOCKET_URL as string,
        options: {
          reconnect: true,
          connectionParams: {
            headers: {
              authorization: `Bearer ${token}`,
              'content-type': 'application/json',
            },
          },
        },
      });
      const splitLink = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        httpLink,
      );

      clientWithSocket.current = new ApolloClient({
        link: authLink.concat(splitLink),
        cache: memoryCache,
        defaultOptions: defaultOptions as any,
      });
    }

    return <ApolloProvider client={clientWithSocket.current}>{children}</ApolloProvider>;
  }

  function getConfig() {
    const httpLink = new HttpLink({
      uri: REACT_APP_APOLLO_GRAPHQL_URL,
    });

    const authLink = setContext(async (_, { headers, ...rest }) => {
      if (!token) return { headers, ...rest };

      return {
        ...rest,
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          if (err.extensions) {
            switch (err.extensions.code) {
              case 'invalid-jwt': {
                logout({
                  returnTo: `${window.location.origin}${ROUTES.login}`,
                });
                break;
              }

              default: {
                toastError(err.message);
              }
            }
          }
        }
      }

      if (networkError) {
        toastError(`[Network error]: ${networkError}`);
      }
    });

    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };
    return {
      httpLink,
      authLink,
      defaultOptions,
    };
  }
};

export default ApolloProviderWithAuth0;
