export class MerchantProfile {
  id: string;
  name: string;
  role: string;
  avatar: string;
  isActive: boolean;
  isApproved: boolean;
  clientProfile: any;

  constructor(id: string, role: string, clientProfile: any) {
    this.id = id;
    this.role = role;
    this.clientProfile = clientProfile;

    this.name = clientProfile?.company_name;
    this.avatar = clientProfile?.logo_file?.url;
    this.isActive = clientProfile?.is_active;
    this.isApproved = clientProfile?.is_approved;
  }

  isAdmin(): boolean {
    return this.role === 'Owner';
  }
}
