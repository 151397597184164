import { useQuery } from '@apollo/client';
import { createContext, useEffect, useState } from 'react';
import GET_LIST_MERCHANT from '../queries/teams/listMerchant';
import useGuard from '../hooks/useGuard';
import CookieUtil, { CookieKeys } from '../utils/cookies';
import { MerchantProfile } from '../types/merchantProfile';

const defaultState = {
  clientProfiles: [] as MerchantProfile[],
  currentProfile: undefined as MerchantProfile | undefined,
  saveCurrentProfile: (profile: MerchantProfile | undefined) => {},
  refetch: () => {},
};
const ClientProfileContext = createContext(defaultState);

export const ClientProfileProvider = ({ children }) => {
  const [currentProfile, setCurrentProfile] = useState<MerchantProfile | undefined>(undefined);
  const [profiles, setProfiles] = useState<MerchantProfile[]>([]);
  const { user } = useGuard();
  const { data: response, refetch } = useQuery(GET_LIST_MERCHANT, {
    variables: {
      email: user?.email,
    },
  });

  function saveCurrentProfile(profile: any) {
    setCurrentProfile(profile);
    if (profile === undefined) {
      CookieUtil.remove(CookieKeys.PROFILE_ID);
    } else {
      CookieUtil.set(CookieKeys.PROFILE_ID, profile?.id);
    }
  }

  useEffect(() => {
    const merchants = (response?.client_profile_user_role || []).map((profile) => {
      return new MerchantProfile(profile.client_profile_id, profile.role, profile.client_profile);
    });
    const profileId = CookieUtil.get(CookieKeys.PROFILE_ID);
    const profile = merchants.find((profile) => profile.id === profileId);
    setProfiles(merchants);
    setCurrentProfile(profile);
  }, [response]);

  return (
    <ClientProfileContext.Provider value={{ clientProfiles: profiles, currentProfile, saveCurrentProfile, refetch: refetch }}>
      {children}
    </ClientProfileContext.Provider>
  );
};

export default ClientProfileContext;
