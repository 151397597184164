import Cookies from 'js-cookie';

const CookieUtil = {
  set: (key: string, value: string): string | undefined => Cookies.set(key, value),
  get: (key: string): string | undefined => Cookies.get(key),
  remove: (key: string): void => Cookies.remove(key),
};

export const CookieKeys = {
  PROFILE_ID: 'PROFILE_ID',
};

export default CookieUtil;
